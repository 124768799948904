import { useContext, useEffect } from 'react';
import { RouterContext } from '../../context/RouterContext';
import { TransportClient } from '../../gen/ApiClients';
import { useClient } from '../../hooks/useClient';
import { useLocalizationContext } from '../../hooks/useLocalizationContext';
import { tryCatchWithLoading } from '../../infrastructure/Utils';
import { calculateNewRoute } from '../../routes';
import './LandingPage.scss';
import React from 'react';

export const LandingPage = () => {
	// const context = useContext(TransportContext);
	const client = useClient(TransportClient);
	const locContext = useLocalizationContext();
	const routerContext = useContext(RouterContext);

	useEffect(
		() => {
			// context.createNew();
			const createNew = async () => {
				const id = await tryCatchWithLoading(client.create(), () => {}, locContext.serverError);
				routerContext.navigate(calculateNewRoute(id));
			};

			createNew();
		},
		// eslint-disable-next-line
		[]
	);

	return <div></div>;
};
