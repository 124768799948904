import React, { useContext, useEffect } from 'react';
import { Header } from './header/Header';
import { TransportContext } from '../../context/TransportSubmitContext';
import { Step1 } from './step1/Step1';
import { Step2 } from './step2/Step2';
import { Step4 } from './step4/Step4';
import { Step3 } from './step3/Step3';
import { useParams } from 'react-router-dom';
import { IRouteParamsWithId } from './IRouteParamsWithId';

export const Wrapper = () => {
	const { id } = useParams<IRouteParamsWithId>();
	const context = useContext(TransportContext);

	useEffect(
		() => {
			context.onMountOrRefresh(id);
		},
		// eslint-disable-next-line
		[id]
	);

	return (
		<div className='df-col-ac stretch-ver'>
			<Header />
			{context.step === 1 && <Step1 />}
			{context.step === 2 && <Step2 />}
			{context.step === 3 && <Step3 />}
			{context.step === 4 && <Step4 />}
			{(context.step < 1 || context.step > 4) && <div>Whoops, nothing found</div>}
		</div>
	);
};
