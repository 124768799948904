import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { BrowserRouterProvider } from './context/RouterContext';
import { library } from '@fortawesome/fontawesome-svg-core';
// library stuff for font-awesome
import { faEyeSlash, faEye } from '@fortawesome/free-regular-svg-icons';
import {
	faCheck,
	faChevronDown,
	faExclamationTriangle,
	faChevronUp,
	faPlus,
	faPlusCircle,
	faTimes,
	faInfoCircle,
	faEnvelope,
} from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ModalProvider } from './context/ModalContext';
import { LocalizationProvider } from './context/LocalizationContext';
import { LandingPage } from './components/landingPage/LandingPage';
import { TransportProvider } from './context/TransportSubmitContext';
import { Wrapper } from './components/blank/Wrapper';
import * as fromRoutes from './routes';
import './App.css';
import { AppUpdateWall } from './components/AppUpdateWall';

library.add(faCheck, faChevronDown, faExclamationTriangle, faChevronUp, faPlus, faPlusCircle, faTimes, faInfoCircle, faEye, faEyeSlash, faEnvelope);

export function App() {
	return (
		<BrowserRouterProvider>
			<LocalizationProvider>
				<ModalProvider>
					<TransportProvider>
						<ToastContainer
							position={toast.POSITION.TOP_CENTER}
							autoClose={3000}
							hideProgressBar={false}
							newestOnTop={true}
							closeOnClick
							closeButton={false}
							transition={Slide}
							draggable={false}
						/>
						<AppUpdateWall>
							<Switch>
								<Redirect
									exact
									path='/11ac1afb-5db8-4f9d-b554-a28f8092a503'
									to={fromRoutes.LandingPageRoute}
								/>
								<Route
									exact
									path={fromRoutes.TransportRoute}
									component={Wrapper}
								/>
								<Route
									exact
									path={fromRoutes.LandingPageRoute}
									component={LandingPage}
								/>
								<Redirect
									path='/'
									to={fromRoutes.LandingPageRoute}
								/>
							</Switch>
						</AppUpdateWall>
					</TransportProvider>
				</ModalProvider>
			</LocalizationProvider>
		</BrowserRouterProvider>
	);
}
